<template>
  <v-container fluid="fluid">
    <v-layout class="flex-column"><span class="ma-5 title">Dashboard</span>

      <div class="d-flex flex-wrap">
        <v-card v-for="total in totals" :key="total.id" class="cardinfos" elevation="24">
          <v-card-title>
            {{ total.title }}
          </v-card-title>
          <div class="infos">
            <h1><i :class="total.icon" style="color:#6565bf;"></i>
              {{ total.value }}</h1>
          </div>
        </v-card>
      </div>
      <div class="d-flex flex-wrap">
        <v-card v-for="item in items" :key="item.id" class="cardgraphs" elevation="24">
          <v-card-title>
            {{ item.title }}
          </v-card-title>

          <PieChart v-if="item.type === 'pie'" :chartData="item.data" />

          <BarChart v-else :chartData="item.data" />

        </v-card>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import PieChart from "../components/Chart/Pie.vue";
import BarChart from "../components/Chart/Bar.vue";
import { db } from "@/plugins/google/firebase";
import { v4 } from "uuid";

const backgroundColor = ["#E46651", "#41B883", "#00D8FF", "#DD1B16", "#E47651", "#FFFF00", "#BA55D3"];

export default {
  name: "home",
  components: {
    PieChart,
    BarChart,
  },
  data: () => ({
    items: [],
    totals: [],
  }),
  activated() {
    this.items = [];
    this.$nextTick(() => {
      this.loadData();
      this.loadTotals();
    });
  },

  methods: {
    async loadData() {
      const schedulesRef = db.collection(
        this.$store.getters.COLLECTION_SCHEDULES,
      );
      const receivablesPayablesRef = db.collection(
        this.$store.getters.COLLECTION_RECEIVABLES_PAYABLES,
      );
      const documentsRef = db.collection(
        this.$store.getters.COLLECTION_DOCUMENTS,
      );

      const schedulesSnap = await schedulesRef
        .where("status", "!=", "Cancelado")
        .get();
      const schedulesDataSet = [];

      const receivablesSnap = await receivablesPayablesRef
        .where("type", "==", "R")
        .get();
      const receivablesDataSet = [];

      const payablesSnap = await receivablesPayablesRef
        .where("type", "==", "P")
        .get();
      const payablesDataSet = [];

      const documentsSnap = await documentsRef.get();
      const documentsDataSet = [];



      schedulesSnap.forEach((value) => {
        const val = value.data();
        const index = schedulesDataSet.findIndex((v) => {
          return v.label === val.service.name;
        });

        if (index > -1) {
          schedulesDataSet[index].data++;
        } else {
          schedulesDataSet.push({
            label: val.service.name,
            data: 1,
          });
        }
      });

      documentsSnap.forEach((value) => {
        const val = value.data();
        const index = documentsDataSet.findIndex((v) => {
          return v.label === val.status;
        });

        if (index > -1) {
          const prev = documentsDataSet[index].data;
          documentsDataSet[index].data = prev + val.total;
        } else {
          documentsDataSet.push({
            label: val.status,
            data: val.total,
          });
        }
      });

      receivablesSnap.forEach((value) => {
        const val = value.data();
        const index = receivablesDataSet.findIndex((v) => {
          return v.label === val.status;
        });

        if (index > -1) {
          const prev = receivablesDataSet[index].data;
          receivablesDataSet[index].data = prev + val.value;
        } else {
          receivablesDataSet.push({
            label: val.status,
            data: val.value,
            color: val.status === "Pendente" ? "#E46651" : "#41B883",
          });
        }
      });

      payablesSnap.forEach((value) => {
        const val = value.data();
        const index = payablesDataSet.findIndex((v) => {
          return v.label === val.status;
        });

        if (index > -1) {
          const prev = payablesDataSet[index].data;
          payablesDataSet[index].data = prev + val.value;
        } else {
          payablesDataSet.push({
            label: val.status,
            data: val.value,
            color: val.status === "Pendente" ? "#E46651" : "#41B883",
          });
        }
      });

      this.items = [
        this.getChartData("Agendamentos", schedulesDataSet, "pie"),
        this.getChartData("Contas a Receber", receivablesDataSet, "bar", true),
        this.getChartData("Contas a Pagar", payablesDataSet, "bar", true),
        this.getChartData("Vendas", documentsDataSet, "bar"),
      ];
    },

    async loadTotals() {
      const customersRef = db.collection(
        this.$store.getters.COLLECTION_ACCOUNTS,
      );
      const animalsRef = db.collection(
        this.$store.getters.COLLECTION_ANIMALS,
      );
      const itemsRef = db.collection(
        this.$store.getters.COLLECTION_ITEMS,
      );
      const schedulesRef = db.collection(
        this.$store.getters.COLLECTION_SCHEDULES,
      );

      const customersSnap = await customersRef
        .where("role", "==", "Cliente")
        .get();
      const animalsSnap = await animalsRef.get();
      const itemsSnap = await itemsRef.get();
      const schedulesSnap = await schedulesRef
        .where("status", "==", "Finalizado")
        .get();

      this.totals = [
        this.getTotalsItem("Agendamentos realizados", schedulesSnap.size, "mdi mdi-calendar-clock"),
        this.getTotalsItem("Clientes cadastrados", customersSnap.size, "mdi mdi-account"),
        this.getTotalsItem("Animais cadastrados", animalsSnap.size, "mdi mdi-dog"),
        this.getTotalsItem("Itens cadastrados", itemsSnap.size, "mdi mdi-cube"),
      ];
    },

    getTotalsItem(title, value, icon) {
      return {
        id: v4(),
        title,
        value,
        icon,
      };
    },
    getChartData(title, data, type, colors) {
      return {
        id: v4(),
        title,
        type,
        data: {
          labels: data.map((v) => v.label),
          datasets: [
            {
              label: title,
              backgroundColor: colors ? data.map((v) => v.color) : backgroundColor,
              data: data.map((v) => v.data),
            },
          ],
        },
      };
    },
  },
};
</script>

<style scoped>
.PieChart {
  width: 80%;
}

.cardinfos {
  margin-top: 5px;
  margin-left: 0.5%;
  width: 272px;
  height: 120px;
}

.infos {
  size: 100px;
  margin-left: 5%;
  height: 30px;
}

.cardgraphs {
  margin-top: 10px;
  width: 550px;
  margin-left: 0.5%;
}

.d-flex {
  margin-left: 5%;
  margin-right: 5%;
}
</style>
