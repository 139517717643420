import Vue from "vue";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import verifyErrorCode from "./verifyErrorCode";
import mSnapshots from "./snapshots";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

export const analytics = firebase.analytics();
export const auth = firebase.auth();
export const db = firebase.firestore();
export const Timestamp = firebase.firestore.Timestamp;
export const getMessageError = verifyErrorCode;
export const snapshots = mSnapshots;

auth.useDeviceLanguage();

db.enablePersistence({ synchronizeTabs: true }).catch(function(err) {
  window.$messages && window.$messages.warning({
    text: getMessageError(err),
    duration: 10000,
  });
});

Vue.use({
  install: (Vue) => {
    Vue.prototype.$firebase = firebase;
  },
});

export default firebase;
