import Vue from "vue";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/pt-br";

dayjs.locale("pt-br");
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

Vue.use({
  install: (Vue) => {
    Vue.prototype.$day = dayjs;
  },
});

export default dayjs;
