import Vue from "vue";
import VueRouter from "vue-router";
import $store from "../store";
import $events from "../plugins/events";

import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Auth/Login.vue";

const AccountEdit = () => import("../views/Base/AccountEdit.vue");
const Accounts = () => import("../views/Base/Accounts.vue");
const AnimalEdit = () => import("../views/Base/AnimalEdit.vue");
const Animals = () => import("../views/Base/Animals.vue");
const DocumentEdit = () => import("../views/Main/DocumentEdit.vue");
const Documents = () => import("../views/Main/Documents.vue");
const EmailLink = () => import("../views/Auth/EmailLink.vue");
const Items = () => import("../views/Base/Items.vue");
const ItemsEdit = () => import("../views/Base/ItemsEdit.vue");
const Phone = () => import("../views/Auth/Phone.vue");
const Privacy = () => import("../views/About/Privacy.vue");
const Profile = () => import("../views/Auth/Profile.vue");
const ReceivablesPayables = () => import("../views/Main/ReceivablesPayables.vue");
const ScheduleEdit = () => import("../views/Main/ScheduleEdit.vue");
const Schedules = () => import("../views/Main/Schedules.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    beforeEnter(to, from, next) {
      // Redireciona para home
      // o beforeRouteLeave de Dashboard nao deixa ir pra rota / novamente
      // Isso resolve o problema de ir dando back no browser até sair da aplicacao
      next("/dashboard");
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/email-link",
    name: "email-link",
    component: EmailLink,
  },
  {
    path: "/phone-auth",
    name: "phone-auth",
    component: Phone,
  },
  {
    path: "/conta",
    name: "profile",
    component: Profile,
    meta: { requiresAuth: true, title: "Conta" },
  },
  {
    path: "/privacidade",
    name: "privacy",
    component: Privacy,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/agendamentos",
    name: "schedules",
    component: Schedules,
    meta: { requiresAuth: true, title: "Agendamentos" },
  },
  {
    path: "/agendamentos/:type",
    name: "schedule-edit",
    component: ScheduleEdit,
    props: true,
    meta: { requiresAuth: true, title: "Agendamento" },
  },
  {
    path: "/vendas",
    name: "documents",
    component: Documents,
    meta: { requiresAuth: true, title: "Vendas" },
  },
  {
    path: "/vendas/:type",
    name: "document-edit",
    component: DocumentEdit,
    props: true,
    meta: { requiresAuth: true, title: "Venda" },
  },
  {
    path: "/receber-pagar",
    name: "receivables-payables",
    component: ReceivablesPayables,
    meta: { requiresAuth: true, title: "Receber e pagar" },
  },
  {
    path: "/cadastros/contas",
    name: "accounts",
    component: Accounts,
    meta: { requiresAuth: true, title: "Pessoas" },
  },
  {
    path: "/cadastros/contas/:type",
    name: "account-edit",
    component: AccountEdit,
    props: true,
    meta: { requiresAuth: true, title: "Cadastro" },
  },
  {
    path: "/cadastros/animais",
    name: "animals",
    component: Animals,
    meta: { requiresAuth: true, title: "Animais" },
  },
  {
    path: "/cadastros/animais/:type",
    name: "animal-edit",
    component: AnimalEdit,
    props: true,
    meta: { requiresAuth: true, title: "Cadastro" },
  },
  {
    path: "/cadastros/itens",
    name: "items",
    component: Items,
    meta: { requiresAuth: true, title: "Itens" },
  },
  {
    path: "/cadastros/itens/:type",
    name: "item-edit",
    component: ItemsEdit,
    props: true,
    meta: { requiresAuth: true, title: "Cadastro do item" },
  },
];

const router = new VueRouter({ routes });

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !$store.state.session) {
    next({ name: "login", query: { redirect: to.fullPath } });
    return;
  }

  if ($store.state.modalContainer) {
    $events.$emit("modal-container", false);
    next(false);
    return;
  }

  next();
});

export default router;
