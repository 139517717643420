<template>
  <v-app-bar app="app" color="#f6f6f6" :clipped-left="false" :dark="false">
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.mdAndDown"
      @click="onNavBarPress"
    >
      <v-icon>mdi-menu</v-icon>
    </v-app-bar-nav-icon>
    <v-toolbar-title
      @click="toolbarTitlePress"
      v-if="!searchVisible || $vuetify.breakpoint.mdAndUp"
      ><span>Time4Pet</span>
      <v-icon v-if="toolbarTitle" small="small">mdi-menu-right</v-icon
      ><span class="font-weight-light" link="link">{{ toolbarTitle }}</span>
    </v-toolbar-title>
    <v-spacer v-if="!searchVisible || $vuetify.breakpoint.mdAndUp"></v-spacer>
    <app-search
      :expanded.sync="searchVisible"
      :type="$route.name"
      :visible="showSearch"
    ></app-search>
  </v-app-bar>
</template>

<script>
import Events from "@/plugins/events";
import AppSearch from "@/components/AppSearch.vue";

export default {
  name: "AppBar",
  components: { AppSearch },

  data: () => ({
    searchVisible: false,
  }),

  computed: {
    showSearch() {
      return ["animals", "accounts", "items"].includes(this.$route.name);
    },

    toolbarTitle() {
      if (this.$route.meta.title) {
        return this.$route.meta.title;
      }
      return "";
    },
  },

  watch: {
    showSearch(v) {
      if (!v) {
        this.searchVisible = false;
      }
    },
  },

  methods: {
    onNavBarPress() {
      Events.$emit("navBarPress");
    },

    toolbarTitlePress() {
      if (this.$route.name !== "home") {
        this.$router.replace({ name: "dashboard" });
      }
    },
  },
};
</script>
