export default (val = "", clean = false) => {
  const num = val ? val.replace(/\D/g, "") : "";
  if (num.length < 8 || clean) {
    return num;
  }
  if (num.length === 8) {
    return num.replace(/^(\d{4})(\d{4})?/, "$1-$2");
  }
  if (num.length === 9) {
    return num.replace(/^(\d{5})(\d{4})?/, "$1-$2");
  }
  if (num.length === 10) {
    return num.replace(/^(\d{2})(\d{4})?(\d{4})?/, "($1) $2-$3");
  }
  if (num.length === 11) {
    return num.replace(/^(\d{2})(\d{5})?(\d{4})?/, "($1) $2-$3");
  }
  if (num.length === 13) {
    return num.replace(/^(\d{3})(\d{2})?(\d{4})?(\d{4})?/, "$1 ($2) $3-$4");
  }
  if (num.length === 14) {
    return num.replace(/^(\d{3})(\d{2})?(\d{5})?(\d{4})?/, "$1 ($2) $3-$4");
  }
  return num;
};
