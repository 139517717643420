export default {
  badge: "Distintivo",
  close: "Fechar",
  dataIterator: {
    noResultsText: "Nenhum registro encontrado",
    loadingText: "Carregando...",
  },
  dataTable: {
    itemsPerPageText: "Exibir:",
    ariaLabel: {
      sortDescending: ": Ordenado decrescente. Ative para remover a ordenação.",
      sortAscending: ": Ordenado crescente. Ative para ordenar decrescente.",
      sortNone: ": Não ordenado. Ative para ordenar crescente.",
    },
    sortBy: "Ordenar por",
  },
  dataFooter: {
    itemsPerPageText: "Exibir:",
    itemsPerPageAll: "Todos",
    nextPage: "Próxima página",
    prevPage: "Página anterior",
    firstPage: "Primeira página",
    lastPage: "Última página",
    pageText: "{0}-{1} de {2}",
  },
  datePicker: {
    itemsSelected: "{0} selecionado(s)",
  },
  noDataText: "Nenhum registro para exibir",
  carousel: {
    prev: "Anterior",
    next: "Próxima",
    ariaLabel: {
      delimiter: "Slide {0} de {1} do carrossel",
    },
  },
  calendar: {
    moreEvents: "Mais {0}",
  },
  fileInput: {
    counter: "{0} arquivo(s)",
    counterSize: "{0} arquivo(s) ({1} no total)",
  },
  timePicker: {
    am: "AM",
    pm: "PM",
  },
};
