<template>
  <v-container
    class="loading-container"
    fluid="fluid"
    fill-height="fill-height"
  >
    <v-layout
      class="loading-container"
      justify-center="justify-center"
      align-center="align-center"
    >
      <v-flex xs12="xs12" sm6="sm6" md3="md3">
        <v-card color="accent" dark="dark" max-width="400">
          <v-card-title class="body-1">Por favor aguarde</v-card-title>
          <v-card-text>
            <v-progress-linear
              class="mb-0"
              indeterminate="indeterminate"
              color="white"
              height="6"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "app-loading",
};
</script>

<style>
.loading-container {
  background-color: #fafafa !important;
}
</style>
