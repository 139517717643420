import Vue from "vue";
import Vuex from "vuex";
import storaged from "./storaged";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    modalContainer: false,
    searchData: {
      animals: [],
      accounts: [],
      items: [],
    },
    session: storaged.getSession(),
    uid: storaged.getUid(),
    accountRoles: ["Administrador", "Cliente", "Outros"],
    accountGenre: ["Feminino", "Masculino"],
  },
  mutations: {
    "modal-container" (state, value) {
      state.modalContainer = value;
    },

    searchData (state, { animals, accounts, items }) {
      accounts && (state.searchData.accounts = accounts);
      animals && (state.searchData.animals = animals);
      items && (state.searchData.items = items);
    },
    session (state, value) {
      if (value && value.uid) {
        value.getIdToken().then(token => {
          state.uid = value.uid;
          state.session = token;
          storaged.setSession(value.uid, token);
        });
      } else {
        state.session = null;
        state.uid = null;
        storaged.setSession(null);
      }
    },
  },
  actions: {
  },
  getters: {
    COLLECTION_ANIMALS: state => {
      return `store/${state.uid}/animals`;
    },
    COLLECTION_ACCOUNTS: state => {
      return `store/${state.uid}/accounts`;
    },
    COLLECTION_DOCUMENTS: state => {
      return `store/${state.uid}/documents`;
    },
    COLLECTION_DOCUMENTS_ITEMS: state => {
      return `store/${state.uid}/documents_items`;
    },
    COLLECTION_ITEMS: state => {
      return `store/${state.uid}/items`;
    },
    COLLECTION_RECEIVABLES_PAYABLES: state => {
      return `store/${state.uid}/receivables_payables`;
    },
    COLLECTION_SCHEDULES: state => {
      return `store/${state.uid}/schedules`;
    },
    COLLECTION_UTILS: state => {
      return `store/${state.uid}/utils`;
    },
    uid: state => {
      return state.uid;
    },
    accountRoles: state => {
      return state.accountRoles;
    },
    accountGenre: state => {
      return state.accountGenre;
    },
  },
  modules: {
  },
});
