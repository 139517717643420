import Vue from "vue";
import Events from "./events";

const dialog = {
  show(title, message, buttons, delay = 100) {
    setTimeout(() => {
      Events.$emit("dialog", { buttons, message, title });
    }, delay);
  },

  dismiss() {
    Events.$emit("dialog", { dismiss: true });
  },
};

Vue.use({
  install: (Vue) => {
    Vue.prototype.$dialog = dialog;
  },
});

export default dialog;
