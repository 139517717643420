<template>
  <v-snackbar
    v-model="visible"
    :timeout="duration"
    :color="$vuetify.theme.dark ? '#181818' : color"
    :multi-line="multiline"
    bottom="bottom"
    >{{ title }}
    <v-btn
      v-if="action.title"
      text="text"
      :color="$vuetify.theme.dark ? 'white' : action.color || '#b2b2df'"
      @click="onActionPress"
      >{{ action.title }}</v-btn
    >
  </v-snackbar>
</template>

<script>
import Events from "../plugins/events";

export default {
  name: "snackbar",
  data() {
    return {
      visible: false,
      action: {},
      color: null,
      duration: 4000,
      multiline: false,
      title: "",
    };
  },

  mounted() {
    Events.$on("snackbar", this.handleEvent);
  },

  beforeDestroy() {
    Events.$off("snackbar", this.handleEvent);
  },

  methods: {
    onActionPress() {
      this.visible = false;
      if (this.action && this.action.onPress) {
        setTimeout(() => this.action.onPress(), 100);
      }
    },

    handleEvent({ dismiss, action, color, duration, multiline, title }) {
      if (dismiss) {
        this.visible = false;
      } else {
        action.title && (this.action = action);
        this.color = color;
        this.duration = duration;
        this.multiline = multiline;
        this.title = title;
        this.visible = true;
      }
    },
  },
};
</script>
