<template>
  <v-layout class="alert-container" row="row" justify-center="justify-center">
    <v-flex lg6="lg6" md8="md8" sm10="sm10" xs10="xs10">
      <v-alert
        class="mt-2"
        :icon="$vuetify.breakpoint.smAndUp ? undefined : false"
        :type="type"
        :value="alert"
        dismissible="dismissible"
        elevation="1"
        transition="fade-transition"
        >{{ text }}</v-alert
      >
    </v-flex>
  </v-layout>
</template>

<script>
import Events from "../plugins/events";

export default {
  name: "alert-message",
  data: () => ({
    action: {},
    alert: false,
    text: "",
    type: "info",
  }),
  mounted() {
    this.timeout = -1;
    Events.$on("alert-message", this.handleEvent);
  },
  beforeDestroy() {
    Events.$off("alert-message", this.handleEvent);
  },
  methods: {
    handleEvent({ action, dismiss, duration, text, type }) {
      clearTimeout(this.timeout);

      if (this.alert && !dismiss) {
        this.alert = false;
      }

      this.$nextTick(() => {
        if (dismiss) {
          this.alert = false;
        } else {
          this.action = action.text ? action : {};
          this.text = text;
          this.type = type;
          this.alert = true;

          this.timeout = setTimeout(() => {
            this.alert = false;
          }, duration);
        }
      });
    },
  },
};
</script>

<style>
.alert-container {
  bottom: 0;
  left: 0;
  max-height: 120;
  position: fixed;
  right: 0;
}
</style>
