import store from "../../store";
import events from "../events";
import { bulkAccountsDocs, bulkAnimalsDocs, bulkItemsDocs } from "../pouchdb";

const snapshots = (db) => {
  const {
    COLLECTION_ANIMALS,
    COLLECTION_ACCOUNTS,
    COLLECTION_DOCUMENTS,
    COLLECTION_DOCUMENTS_ITEMS,
    COLLECTION_ITEMS,
    COLLECTION_RECEIVABLES_PAYABLES,
    COLLECTION_SCHEDULES,
    COLLECTION_UTILS,
  } = store.getters;
  const l = {};

  l.accounts = db.collection(COLLECTION_ACCOUNTS)
    .onSnapshot((v) => {
      bulkAccountsDocs(v);
      events.$emit("snapshot", { type: "accounts", data: v });
    });

  l.animals = db.collection(COLLECTION_ANIMALS)
    .onSnapshot((v) => {
      bulkAnimalsDocs(v);
      events.$emit("snapshot", { type: "animals", data: v });
    });

  l.documents = db.collection(COLLECTION_DOCUMENTS)
    .onSnapshot((v) => {
      events.$emit("snapshot", { type: "documents", data: v });
    });

  l.documentsItems = db.collection(COLLECTION_DOCUMENTS_ITEMS)
    .onSnapshot((v) => {
      events.$emit("snapshot", { type: "documents-items", data: v });
    });

  l.items = db.collection(COLLECTION_ITEMS)
    .onSnapshot((v) => {
      bulkItemsDocs(v);
      events.$emit("snapshot", { type: "items", data: v });
    });

  l.receivablesPayables = db.collection(COLLECTION_RECEIVABLES_PAYABLES)
    .onSnapshot((v) => {
      events.$emit("snapshot", { type: "receivables-payables", data: v });
    });

  l.schedules = db.collection(COLLECTION_SCHEDULES)
    .onSnapshot((v) => {
      events.$emit("snapshot", { type: "schedules", data: v });
    });

  l.utils = db.collection(COLLECTION_UTILS)
    .onSnapshot((v) => {
      events.$emit("snapshot", { type: "utils", data: v });
    });

  return {
    unsubscribe () {
      l.accounts && l.accounts();
      l.animals && l.animals();
      l.documents && l.documents();
      l.documentsItems && l.documentsItems();
      l.items && l.items();
      l.receivablesPayables && l.receivablesPayables();
      l.schedules && l.schedules();
      l.utils && l.utils();
    },
  };
};

export default snapshots;