import Vue from "vue";
import calendar from "./calendar";
import capitalize from "./capitalize";
import cepMask from "./cepMask";
import decimal from "./decimal";
import docMask from "./docMask";
import phoneMask from "./phoneMask";
import ufs from "../../assets/ufs";
import validateDocs from "./validateDocs";
import validateEmail from "./validateEmail";
import valueFormatter from "./valueFormatter";

const utils = {
  calendar,
  capitalize,
  cepMask,
  decimal,
  decimalBRL(num, abs) {
    return `R$ ${decimal(num, abs ? 0 : 2)}`;
  },
  decimalKG(num, decimals) {
    return `${decimal(num, decimals)} KG`;
  },
  decimalBank(num) {
    num = num / 100;
    return decimal(num, 2);
  },
  decimalPer(num, abs) {
    return `${decimal(num, abs ? 0 : 2)}%`;
  },
  docMask,
  phoneMask,
  ufs,
  validateDocs,
  validateEmail,
  valueFormatter,
};

Vue.use({
  install: (Vue) => {
    Vue.prototype.$utils = utils;
    Vue.filter("calendar", utils.calendar);
    Vue.filter("cepMask", utils.cepMask);
    Vue.filter("docMask", utils.docMask);
    Vue.filter("phoneMask", utils.phoneMask);
  },
});

export default utils;
