export default (v) => {
  const a = v?.toString()?.split(".");
  if (!a) {
    return "";
  }
  if (a.length == 1) {
    return `${a[0]}.00`;
  }
  switch (a[1].length) {
    case 1:
      return `${a[0]}.${a[1]}0`;
    default:
      return `${a[0]}.${a[1]}`;
  }
};
