<template>
  <v-navigation-drawer :clipped="false" :color="color" :mini-variant="miniVariant" :src="bg" app="app" dark="dark"
    mini-variant-width="68" v-model="drawer">
    <v-list class="py-0" dense="dense" nav="nav">
      <v-list-item two-line="two-line" link="link" @click="avatarPress">
        <v-list-item-avatar>
          <img src="https://picsum.photos/64" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Time4Pet</v-list-item-title>
          <v-list-item-subtitle>Pets</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-tooltip :disabled="!miniVariant" :key="item.title" color="#212121" right="right" v-for="item in computedItems">
        <template v-slot:activator="{ on }">
          <v-list-item link="link" v-on="on" @click="onDrawerItemPress(item.route)">
            <v-list-item-icon v-on="on">
              <v-icon :color="
                drawerItemActive(item) ? 'light-blue accent-2' : undefined
              ">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title :class="
                drawerItemActive(item)
                  ? 'light-blue--text text--accent-2'
                  : undefined
              ">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template><span>{{ item.title }}</span>
      </v-tooltip>
      <v-footer class="pr-2 d-flex flex-row-reverse" absolute="absolute" color="accent" height="72" padless="padless">
        <v-btn icon="icon" @click="miniVariant = !miniVariant" x-large="x-large">
          <v-icon dark="dark">{{ chevronIcon }}</v-icon>
        </v-btn>
      </v-footer>
    </v-list>
  </v-navigation-drawer>
</template>

<script>


import Events from "../plugins/events";
import { auth } from "../plugins/google/firebase";

const API_URL =
  process.env.NODE_ENV !== "production"
    ? process.env.VUE_APP_API_LOCAL
    : process.env.VUE_APP_API_URL;

export default {
  name: "AppDrawer",

  data: () => ({
    drawer: true,
    color: "accent",
    miniVariant: true,
    background: false,
    items: [
      { title: "Dashboard", icon: "mdi-view-dashboard", route: "dashboard" },
      { title: "Agendamentos", icon: "mdi-store", route: "schedules" },
      { title: "Vendas", icon: "mdi-briefcase", route: "documents" },
      { title: "Receber e pagar", icon: "mdi-swap-vertical", route: "receivables-payables"},
      { title: "Pessoas", icon: "mdi-account", route: "accounts" },
      { title: "Animais", icon: "mdi-paw", route: "animals" },
      { title: "Itens", icon: "mdi-cart", route: "items" },
      { title: "Sobre", icon: "mdi-information", route: "privacy" },
      { title: "Sair", icon: "mdi-logout", route: "logout" },
    ],

  }),

  mounted() {
    Events.$on("navBarPress", this.onNavBarPress);
    this.miniVariant = this.$vuetify.breakpoint.lgAndUp;
  },

  beforeMount() {
    this.drawer = this.$vuetify.breakpoint.lgAndUp;
  },

  beforeDestroy() {
    Events.$off("navBarPress", this.onNavBarPress);
  },

  computed: {
    bg() {
      return this.background
        ? "https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
        : undefined;
    },
    chevronIcon() {
      return this.miniVariant ? "mdi-chevron-right" : "mdi-chevron-left";
    },
    computedItems() {
      return this.items.filter((item) => this.drawerItemVisible(item));
    },
  },

  methods: {
    avatarPress() {
      if (this.$route.name !== "profile") {
        this.$router.replace({ name: "profile" });
      }
    },

    drawerItemActive({ route }) {
      switch (route) {
        case "dashboard":
          return this.$route.fullPath.includes("dashboard");
        case "schedules":
          return this.$route.fullPath.includes("agendamentos");
        case "documents":
          return this.$route.fullPath.includes("vendas");
        case "receivables-payables":
          return this.$route.fullPath.includes("receber");
        case "accounts":
          return this.$route.fullPath.includes("contas");
        case "animals":
          return this.$route.fullPath.includes("animais");
        case "items":
          return this.$route.fullPath.includes("itens");
        default:
          return this.$route.name === route;
      }
    },

    drawerItemVisible({ route }) {
      const l = [
        "accounts",
        "animals",
        "dashboard",
        "documents",
        "items",
        "receivables-payables",
        "schedules",
      ];
      if (l.includes(route)) {
        return this.$store.getters.uid !== null;
      }
      return true;
    },

    onDrawerItemPress(name) {
      if (name == "logout") {
        this.logout();
        return;
      }
      if (this.$route.name !== name) {
        this.$router.replace({ name });
      }
    },

    onNavBarPress() {
      this.drawer = !this.drawer;
    },

    logout() {
      this.$dialog.show(
        "Sair do sistema",
        "Deseja realmente sair do sistema?",
        [
          { text: "Cancelar" },
          {
            text: "Sair",
            style: "destrutive",
            onClick: () => {
              auth.signOut();
              this.$store.commit("session", null);
              setTimeout(() => window.location.replace(`${API_URL}/#/login`), 250);
            },
          },
        ],
      );
    },

  },
};
</script>

<style>
.chevron-btn {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
</style>
