import day from "../dayjs";

export default (value, type = "datetime") => {
  try {
    switch (type) {
      case "time":
        return day(value).format("HH:mm");
      case "datetime":
        return day(value).format("DD/MM/YYYY HH:mm");
      case "date":
        return day(value).format("DD/MM/YYYY");
      case "month":
        return day(value).format("MMM YYYY").toUpperCase();
      case "y":
        return day(value).format("YYYY");
    }
  } catch (e) {
    return value;
  }
};
