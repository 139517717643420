<template>
  <v-container class="login-container" fluid="fluid" fill-height="fill-height">
    <v-layout
      class="login-container"
      justify-center="justify-center"
      align-center="align-center"
    >
      <v-flex xs12="xs12" sm8="sm8" md4="md4">
        <v-card
          class="mx-auto px-1 elevation-4"
          :disabled="loading"
          :loading="loading"
          max-width="400"
          min-height="210"
        >
          <v-card-title class="font-weight-light">Time4Pet</v-card-title>
          <v-spacer></v-spacer>
          <v-card-text class="subtitle-1 text-center">
            <v-btn
              class="mb-4"
              @click="signInWithPhoneNumber"
              color="#00c780"
              dark="dark"
              large="large"
              width="250"
            >
              <v-icon left="left">mdi-phone</v-icon
              ><span>Entrar com telefone</span>
            </v-btn>
            <v-btn
              class="mb-4"
              @click="signInWithRedirectGoogle"
              color="#4285f4"
              dark="dark"
              large="large"
              width="250"
            >
              <v-icon left="left" dark="dark">mdi-google</v-icon
              ><span>Entrar com Google</span>
            </v-btn>
            <v-btn
              class="mt-4"
              @click="signInWithEmailLink"
              small="small"
              text="text"
              color="accent"
              >Entrar com e-mail</v-btn
            >
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import firebase, { auth } from "../../plugins/google/firebase";

export default {
  name: "login",
  data: () => ({
    loading: false,
  }),
  mounted() {
    if (this.$store.state.session) {
      if (this.$route.query.redirect) {
        this.$router.replace(this.$route.query.redirect);
      } else {
        this.$router.replace("/");
      }
    }
  },
  methods: {
    signInWithPhoneNumber() {
      this.$router.push({ name: "phone-auth" });
    },
    signInWithEmailLink() {
      this.$router.push({ name: "email-link" });
    },
    signInWithRedirect(provider) {
      this.loading = true;
      window.localStorage.setItem("providerForSignIn", provider.providerId);
      auth.signInWithRedirect(provider);
    },
    signInWithRedirectGoogle() {
      const provider = new firebase.auth.GoogleAuthProvider();
      this.signInWithRedirect(provider);
    },
  },
};
</script>

<style>
.login-container {
  background-color: #6565bf !important;
}
</style>
