<template>
  <v-dialog
    class="ma-0"
    v-model="visible"
    persistent="persistent"
    max-width="320"
  >
    <v-card>
      <v-card-title class="title">{{ title }}</v-card-title>
      <v-card-text>{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <template v-for="(button, index) in buttons">
          <v-btn
            :color="handleStyle(button.style)"
            :key="`btn-${index}`"
            @click="handleAction(button.onClick)"
            min-height="40"
            min-width="80"
            text="text"
            >{{ button.text }}</v-btn
          >
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Events from "../plugins/events";

export default {
  name: "app-dialog",
  data() {
    return {
      visible: false,
      title: "",
      message: "",
      buttons: [{ text: "ok" }],
    };
  },

  mounted() {
    Events.$on("dialog", this.handleEvent);
  },

  beforeDestroy() {
    Events.$off("dialog", this.handleEvent);
  },

  methods: {
    handleEvent({ dismiss, title, message, buttons }) {
      this.visible = !dismiss;
      this.title = title || "";
      this.message = message || "";
      this.buttons = buttons || [{ text: "ok" }];
    },

    handleAction(callback) {
      this.visible = false;
      callback && callback();
    },

    handleStyle(style) {
      if (style && style === "destrutive") {
        return this.$vuetify.theme.dark ? "error lighten-1" : "error darken-1";
      }
      return this.$vuetify.theme.dark ? "white" : "accent";
    },
  },
};
</script>
