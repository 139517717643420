const storaged = window.localStorage;

function getSession() {
  const s = storaged.getItem("time4pet:session");
  return s || null;
}

function getUid() {
  const s = storaged.getItem("time4pet:uid");
  return s || null;
}

function setSession(uid, token) {
  requestAnimationFrame(() => {
    if (!uid) {
      storaged.removeItem("time4pet:session");
      storaged.removeItem("time4pet:uid");
    } else {
      storaged.setItem("time4pet:session", token);
      storaged.setItem("time4pet:uid", uid);
    }
  });
}

export default {
  getSession,
  getUid,
  setSession,
};
