import Vue from "vue";
import Events from "./events";

const snackbar = {
  show({
    action = {},
    color = "",
    duration = 4000,
    multiline = false,
    title = "",
  }, delay = 250) {
    setTimeout(() => {
      Events.$emit("snackbar", {
        action,
        color,
        duration,
        multiline,
        title,
      });
    }, delay);
  },

  dismiss() {
    Events.$emit("snackbar", { dismiss: true });
  },
};

Vue.use({
  install: (Vue) => {
    Vue.prototype.$snackbar = snackbar;
  },
});

export default snackbar;
