import PouchDB from "pouchdb-browser";
import store from "../store";
const pdb = new PouchDB("search");

export const allCacheAnimals = async () => {
  const docs = await pdb.allDocs({
    include_docs: true,
    startkey: "animals",
    endkey: "animals\ufff0",
  });
  return docs.rows;
};

export const allCacheAccounts = async () => {
  const docs = await pdb.allDocs({
    include_docs: true,
    startkey: "accounts",
    endkey: "accounts\ufff0",
  });
  return docs.rows;
};

export const allCacheItems = async () => {
  const docs = await pdb.allDocs({
    include_docs: true,
    startkey: "items",
    endkey: "items\ufff0",
  });
  return docs.rows;
};

export const bulkAccountsDocs = async (snapshot) => {
  try {
    await bulkDocs("accounts", snapshot);
    const accounts = await allCacheAccounts();
    store.commit("searchData", { accounts });
  } catch (error) {
    /* error */
  }
};

export const bulkAnimalsDocs = async (snapshot) => {
  try {
    await bulkDocs("animals", snapshot);
    const animals = await allCacheAnimals();
    store.commit("searchData", { animals });
  } catch (e) {
    /* e */
  }
};

export const bulkItemsDocs = async (snapshot) => {
  try {
    await bulkDocs("items", snapshot);
    const items = await allCacheItems();
    store.commit("searchData", { items });
  } catch (e) {
    /* e */
  }
};

const bulkDocs = async (name, snapshot) => {
  if (!store.getters.uid) {
    throw "cancelled";
  }

  const revs_key = `${store.getters.uid}:pouchdb_revs:${name}`;

  let revs = window.localStorage.getItem(revs_key);
  revs = revs ? JSON.parse(revs) : {};

  const docs = [];
  snapshot.docChanges().forEach(val => {
    const rev = revs[`${name}_${val.doc.id}`] || null;

    if (val.type === "added" && rev) {
      return;
    }

    const doc = { _id: `${name}_${val.doc.id}` };

    if (rev) {
      doc._rev = rev;
    }

    if (val.type === "removed") {
      doc._deleted = true;
    } else {
      doc.id = val.doc.id;
      doc.updatedAt = val.doc.get("updatedAt");
      doc.title = val.doc.get("name");
    }

    const owner = val.doc?.get("owner");
    const code = val.doc?.get("code");

    if (owner) {
      doc.owner = owner.name;
    }
    if (code) {
      doc.code = code;
    }

    docs.push(doc);
  });

  if (!docs.length) {
    return;
  }

  await pdb.bulkDocs(docs).then(result => {
    const setRevs = revs;
    result.forEach(v => {
      setRevs[v.id] = v.rev;
    });
    window.localStorage.setItem(revs_key, JSON.stringify(setRevs));
  });
};

export default pdb;
