<template>
  <v-app>
    <app-bar v-if="showApp && ready"></app-bar>
    <app-drawer v-if="showApp && ready"></app-drawer>
    <v-main class="app-container-background">
      <transition name="fade" mode="out-in">
        <app-loading v-if="!ready" color="primary" dark="dark"></app-loading>
        <keep-alive v-else :include="['database', 'home', 'privacy']">
          <router-view></router-view>
        </keep-alive>
      </transition>
    </v-main>
    <alert-message></alert-message>
    <snackbar></snackbar>
    <app-dialog></app-dialog>
  </v-app>
</template>

<script>
import AlertMessage from "./components/AlertMessage.vue";
import AppBar from "./components/AppBar.vue";
import AppDialog from "./components/AppDialog.vue";
import AppDrawer from "./components/AppDrawer.vue";
import AppLoading from "./components/AppLoading.vue";
import Snackbar from "./components/Snackbar.vue";

import {
  auth,
  getMessageError,
  snapshots,
  db,
} from "./plugins/google/firebase";
import Events from "./plugins/events";
import messages from "./plugins/messages";
import snackbar from "./plugins/snackbar";

export default {
  name: "App",
  components: {
    AppBar,
    AppDrawer,
    AppDialog,
    AppLoading,
    AlertMessage,
    Snackbar,
  },

  data: () => ({
    ready: false,
  }),

  mounted() {
    window.$messages = messages;
    window.$snackbar = snackbar;
    this.validateSession();
  },

  beforeDestroy() {
    this.snapshots && this.snapshots.unsubscribe();
  },

  computed: {
    showApp() {
      return !["login", "email-link", "phone-auth"].includes(this.$route.name);
    },
  },
  methods: {
    validateSession() {
      this.authVerification();
      auth.onAuthStateChanged((user) => {
        if (user && user.uid) {
          this.$store.commit("session", user);
          Events.$emit("authSuccessful");
          setTimeout(() => {
            this.snapshots = this.snapshots || snapshots(db);
          }, 450);
        } else {
          this.snapshots && this.snapshots.unsubscribe();
          this.$store.commit("session", null);
        }
        !this.ready && (this.ready = true);
      });
    },
    authVerification() {
      const providerId = window.localStorage.getItem("providerForSignIn");
      window.localStorage.removeItem("providerForSignIn");

      if (providerId) {
        auth.getRedirectResult().catch((e) => this.handleErrors(e));
      }
    },
    handleErrors(e) {
      this.$messages.error({ text: getMessageError(e), duration: 20000 });
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.app-container-background {
  background-color: #fafafa !important;
}
</style>
