import validateCnpj from "./validateCnpj";
import validateCpf from "./validateCpf";

export default (val, tipo) => {
  const num = val.replace(/\D/g, ""), size = num.length;
  if (size !== 11 && size !== 14) {
    return false;
  }

  if (size === 11 && tipo && tipo !== "F") {
    return false;
  }

  if (size === 14 && tipo && tipo !== "J") {
    return false;
  }

  let arr = [
    "00000000000",
    "11111111111",
    "22222222222",
    "33333333333",
    "44444444444",
    "55555555555",
    "66666666666",
    "77777777777",
    "88888888888",
    "99999999999",
    "00000000000000",
    "11111111111111",
    "22222222222222",
    "33333333333333",
    "44444444444444",
    "55555555555555",
    "66666666666666",
    "77777777777777",
    "88888888888888",
    "99999999999999",
  ];

  if (arr.includes(num)) {
    return false;
  }
  if (size > 11) {
    return validateCnpj(num, size);
  }
  return validateCpf(num);
};
