import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/dayjs";
import "./plugins/dialog";
import "./plugins/events";
import "./plugins/google/firebase";
import "./plugins/messages";
import "./plugins/observeVisibility";
import "./plugins/registerServiceWorker";
import "./plugins/snackbar";
import "./plugins/utils/index";
import "./plugins/vueCurrencyInput";

const isProduction = process.env.NODE_ENV === "production";

Vue.config.productionTip = isProduction;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount("#app");
