import Vue from "vue";
import Events from "./events";

function show(options, delay) {
  setTimeout(() => {
    Events.$emit("alert-message", options);
  }, delay);
}

const messages = {
  success({
    action = {},
    duration = 6000,
    text = "",
  }, delay = 250) {
    show({ action, duration, text, type: "success" }, delay);
  },
  info({
    action = {},
    duration = 6000,
    text = "",
  }, delay = 250) {
    show({ action, duration, text, type: "info" }, delay);
  },
  warning({
    action = {},
    duration = 6000,
    text = "",
  }, delay = 250) {
    show({ action, duration, text, type: "warning" }, delay);
  },
  error({
    action = {},
    duration = 6000,
    text = "",
  }, delay = 250) {
    show({ action, duration, text, type: "error" }, delay);
  },
  dismiss() {
    Events.$emit("alert-message", { dismiss: true });
  },
};

Vue.use({
  install: (Vue) => {
    Vue.prototype.$messages = messages;
  },
});

export default messages;
