import Vue from "vue";

const EventBus = new Vue();

Vue.use({
  install: (Vue) => {
    Vue.prototype.$events = EventBus;
  },
});

export default EventBus;
