<template>
  <v-layout class="d-flex justify-end" v-if="visible">
    <v-autocomplete
      :cache-items="false"
      :filter="filter"
      :item-text="itemText"
      :item-value="itemValue"
      :items="items[type]"
      :loading="loading"
      :search-input.sync="search"
      autofocus="autofocus"
      color="accent"
      hide-details="hide-details"
      hide-no-data="hide-no-data"
      no-data-text="Nenhum resultado"
      placeholder="Buscar..."
      return-object="return-object"
      v-if="expanded"
      v-model="select"
    >
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title v-html="data.item.doc.title"></v-list-item-title>
          <v-list-item-subtitle
            class="caption"
            v-html="data.item.doc.owner || data.item.doc.code"
            v-if="data.item.doc.owner || data.item.doc.code"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-btn icon="icon" @click="toggle">
      <v-icon>{{ expanded ? "mdi-close" : "mdi-magnify" }}</v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
import Events from "@/plugins/events";

export default {
  name: "app-search",
  props: {
    type: String,
    visible: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    expanded: false,
    items: { animals: [], accounts: [] },
    loading: false,
    search: null,
    select: null,
  }),

  watch: {
    search(v) {
      v && v !== this.select && this.querySelections(v);
    },

    select(value) {
      Events.$emit("searchData", { value, type: this.type });
    },

    expanded(v) {
      if (!v) {
        this.search = null;
        this.select = null;
      }
    },

    visible(v) {
      if (!v) {
        this.expanded = false;
      }
    },
  },

  methods: {
    filter(item, queryText) {
      const title = item.doc.title.toLowerCase();
      const owner = item.doc.owner?.toLowerCase();
      const code = item.doc.code?.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        title.indexOf(searchText) > -1 ||
        owner?.indexOf(searchText) > -1 ||
        code?.indexOf(searchText) > -1
      );
    },

    getCurrentData() {
      return this.$store.state.searchData[this.type];
    },

    itemText(e) {
      return e.doc.title;
    },

    itemValue(e) {
      if (e.doc.owner) {
        return [e.doc.title, e.doc.owner];
      }
      if (e.doc.code) {
        [e.doc.title, e.doc.code];
      }
      return e.doc.title;
    },

    toggle() {
      this.expanded = !this.expanded;
      this.$emit("update:expanded", this.expanded);
    },

    querySelections(v) {
      clearTimeout(this.timeoutID);
      this.loading = true;
      this.timeoutID = setTimeout(() => {
        this.items[this.type] = this.getCurrentData().filter((e) => {
          const v0 = e.doc.title?.toLowerCase().indexOf(v?.toLowerCase()) > -1;
          const v1 = e.doc.owner?.toLowerCase().indexOf(v?.toLowerCase()) > -1;
          const v2 = e.doc.code?.toLowerCase().indexOf(v?.toLowerCase()) > -1;
          return v0 || v1 || v2;
        });
        this.loading = false;
      }, 600);
    },
  },
};
</script>

<style scoped>
.v-text-field {
  padding-top: 0;
}
</style>
